import { useLocation, useNavigate } from "react-router-dom"
import image from '../images/profile.jpeg'
import DateRangeIcon from '@mui/icons-material/DateRange';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import DescriptionIcon from '@mui/icons-material/Description';
import PeopleIcon from '@mui/icons-material/People';
import moment from 'moment';
import { useEffect, useState } from "react";
import { UserAuth } from "../contexts/AuthContext";
import { UseParticipantContext } from "../contexts/ParticipantContext";
import EventInviteModal from "../modals/EventInviteModal";
import { useFetch } from "../hooks/useFetch";
import { baseUrl } from "../api";

const EventInfo = () => {
  const GOING = 'GOING'
  const INTERESTED = 'INTERESTED'
  const NOT_GOING = 'NOT_GOING'
  const noParticipant = {
    id: '',
    eventId: '',
    userId: '',
    status: NOT_GOING
  }
  const navigate = useNavigate()
  const { state } = useLocation()
  const event = state

  const [isOpen, setIsOpen] = useState(false)
  const [goingCount, setGoingCount] = useState(0)
  const [interestedCount, setInterestedCount] = useState(0)
  const [participant, setParticipant] = useState(noParticipant)
  const { user } = UserAuth();
  const { fetchParticipant, addParticipant, deleteParticipant, updateParticipant } = UseParticipantContext();
  const {data:friendsToInvite, isLoading: friendsLoading, error:friendsError} = useFetch(`${baseUrl}/friends/invite/${user.uid},${event.id}`)
  

  const format = ('ddd, MMM D HH:mm')
  const {title, location, startDateTime, endDateTime, privacy, description, hostUserId} = event
  const startDateMoment = moment.utc(startDateTime, 'MMM DD, YYYY, H:mm:00 A').local().format(format)
  const endDateMoment = moment.utc(endDateTime, 'MMM DD, YYYY, H:mm:00 A').local().format(format)

  const isParticipating = participant.status === GOING || participant.status === INTERESTED

  useEffect(() => {
    const getParticipant = async () => {
      const fetchedParticipant = await fetchParticipant(event.id, user.uid)
      setParticipant(fetchedParticipant)
    }

    getParticipant()
  }, [])


  const handleEdit = () => {
    navigate('/edit_event', {state: event})
  }

  const rsvpClick = async (clickedRsvp) => {
    let newParticipant = {
      eventId: event.id,
      userId: user.uid,
      status: clickedRsvp
    }
    await addParticipant(newParticipant)
    newParticipant = await fetchParticipant(event.id, user.uid)
    setParticipant(newParticipant)
  }

  const handleOnMenuChange = (value) => {
    
    if(value === NOT_GOING) {
      deleteParticipant(participant.id)
      setParticipant(noParticipant)
    } else {
      setParticipant((prev) => {
        return {...prev, status: value}
      })
      const updatedParticipant = {id:participant.id, eventId:event.id, userId:user.uid, status:value}
      updateParticipant(updatedParticipant)
    }
  }

  const handleInvite = () => {
    console.log('handle invite')
  }

  return (
    <>
      <EventInviteModal invite={handleInvite} friends={friendsToInvite} open={isOpen} close={()=>{setIsOpen(false)}}/>
      <div className='event-info-container'>
        <div className='event-info-header'>
          <img className='event-picture' src={image}></img>
          <div className='event-info-title'>
            <h1>{title}</h1>
            <p>({privacy})</p>
          </div>
          <div className='event-info-btns'>
            {user.uid === hostUserId
              ? <button className='event-info-btn' onClick={handleEdit}>Edit</button>
              : <></>
            }
            <button id='event-info' className='event-info-btn' onClick={() => {setIsOpen(true)}}>Invite</button>
            { !isParticipating
            ? <><button className='event-info-btn' onClick={() => {rsvpClick(INTERESTED)}}>Interested</button><button className='event-info-btn' onClick={() => {rsvpClick(GOING)}}>Going</button></>
            : <><select className='event-info-btn' value={participant.status} onChange={(e) => {
              handleOnMenuChange(e.target.value)
            }}>
                <option value='GOING'>Going</option>
                <option value='INTERESTED'>Interested</option>
                <option value='NOT_GOING'>Not Going</option>
              </select></>
            }
          </div>
        </div>
        <div className='divider'></div>
        <h2>Details:</h2>
        <ul className='event-info-list'>
          <li className='info-row'>
            <div id='icon'><PeopleIcon fontSize='inherit'/> </div>
            <div id='text'>{goingCount} people going <br/> {interestedCount} people interested</div>
          </li>
          <li className='info-row'>
            <div id='icon'><DateRangeIcon fontSize="inherit"/></div>
            <div id='text'>
              {startDateMoment.toString()} - {endDateMoment.toString()}
            </div>
          </li>
          <li className='info-row'>
            <div id='icon'><LocationCityIcon fontSize="inherit"/></div>
            <div id='text'>{location}</div>
          </li>
          <li className='info-row'>
            <div id='icon'><DescriptionIcon fontSize="inherit"/></div>
            <div id='text'>{description}</div>
          </li>
        </ul>
      </div>
    </>
  )
}

export default EventInfo
