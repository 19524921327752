import FriendInvite from "./FriendInvite"

const FriendInvites = ({invites, accept, decline, onClick}) => {
  if(invites.length === 0) return <h1>You have no pending invites</h1>
  return (
    <>
      <div className='friends-row'>
        {invites.map((invite) => (
            <FriendInvite key={invite.id} invite={invite} accept={accept} decline={decline} onClick={onClick}/>
        ))}
      </div>
    </>
  )
}

export default FriendInvites
