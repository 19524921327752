import { useLocation } from "react-router-dom"
import profilePicture from '../images/profile.jpeg'
import Sidebar from "./Sidebar"
import { useState, useEffect } from "react"
import { useFetch } from "../hooks/useFetch"
import Events from "./Events"
import { UserAuth } from "../contexts/AuthContext"
import { baseUrl } from "../api"

const OthersProfile = () => {
  const { state } = useLocation()
  const profileUser = state
  const { user } = UserAuth()

  const upcomingEventsURL = `${baseUrl}/events/upcoming/${profileUser.id}`
  const pastEventsURL = `${baseUrl}/events/past/${profileUser.id}`
  const isFriendURL = `${baseUrl}/friends/${user.uid},${profileUser.id}`

  const {data:upcomingEvents, isLoading:isUpcomingLoading, error:upcomingError} = useFetch(upcomingEventsURL)
  const {data:pastEvents, isLoading:isPastLoading, error:pastError} = useFetch(pastEventsURL)
  const {data:friend, isLoading:friendLoading, error:friendError} = useFetch(isFriendURL)
  const [inviteFlag, setInviteFlag] = useState(false)
  const [respondedFlag, setRespondedFlag] = useState(false)

  const handleSendInvite = () => {
    addFriendInvite()
    setInviteFlag(true)
  }

  const handleAccept = () => {
    updateFriendInvite()
    setRespondedFlag(true)
  }

  const handleDecline = () => {
    declineFriendInvite()
    setRespondedFlag(true)
  }

  const addFriendInvite = () => {
    const friendInvite = {senderId:user.uid, receiverId: profileUser.id}
    fetch(`${baseUrl}/friends/add`, {
      method: 'POST',
      headers: {
        'Content-Type' : 'application/json'
      },
      body: JSON.stringify(friendInvite)
    })
  }

  const updateFriendInvite = () => {
    const updatedFriend = {...friend, status:'ACCEPTED'}
    fetch(`${baseUrl}/friends/update`, {
      method: 'POST',
      headers: {
        'Content-Type' : 'application/json'
      },
      body: JSON.stringify(updatedFriend)
    })
  }

  const declineFriendInvite = () => {
    fetch(`${baseUrl}/friends/delete/${friend.id}`,{
      method: 'DELETE',
    })
  }

  const clickEvent = () => {
    console.log('click event')
  }
  
  return (
    <>
      <Sidebar/>
      <div className='homepage-content-container'>
        <div className='profile-container'>
          <img className='profile-picture' src={profilePicture}></img>
          <h1>{profileUser.username}</h1>
          <p>{profileUser.bio}</p>
          {Object.keys(friend).length === 0 && !inviteFlag
            ? <button id='add_friend' className='btn' onClick={() => {handleSendInvite()}}>Add Friend</button>
            : <>{friend.status === 'ACCEPTED' || respondedFlag
                  ? <></>
                  : <>{friend.senderId === user.uid || inviteFlag
                    ? <div>Invite Sent</div>
                    : <>
                        <button id='accept' className='btn' onClick={()=> {handleAccept()}}>Accept</button>
                        <button id='delete' className='btn' onClick={()=>{handleDecline()}}>Decline</button>
                      </>
              }</>
            }</>
          }
          
        </div>
        {isUpcomingLoading ? <div>Loading...</div>
        : <div>
              <h1>Upcoming Events</h1>
              <Events events={upcomingEvents} clickEvent={clickEvent}/>
            </div>
        }
        {isPastLoading ? <></>
        : <div className='past-events-container'>
              <h1>Past Events</h1>
              <Events events={pastEvents} clickEvent={clickEvent}/>
            </div>}
      </div>
    </>
  )
}

export default OthersProfile
