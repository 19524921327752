import Sidebar from "./Sidebar"
import {UserAuth} from '../contexts/AuthContext'
import { useEffect, useState } from "react"
import profilePicture from '../images/profile.jpeg'
import Events from './Events'
import { useNavigate } from "react-router-dom"
import { UseEventContext } from "../contexts/EventContext"
import FriendsModal from "../modals/FriendsModal"
import { useFetch } from "../hooks/useFetch"
import { baseUrl } from "../api"

const Profile = () => {
  const navigate = useNavigate()
  const { user } = UserAuth()
  const friendsUrl = `${baseUrl}/friends/accepted/${user.uid}`
  const friendInvitesUrl = `${baseUrl}/friends/invites/${user.uid}`

  const [currentUser, setCurrentUser] = useState({})
  const [upcomingEvents, setUpcomingEvents] = useState([])
  const [pastEvents, setPastEvents] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const { fetchUpcomingEvents, fetchPastEvents } = UseEventContext()

  const {data:friends, isLoading:friendsLoading, error:friendsError} = useFetch(friendsUrl)
  const {data:friendInvites, isLoading:friendInvitesLoading, error:friendInvitesError} = useFetch(friendInvitesUrl)

  useEffect(() => {
    const getUser = async () => {
      const fetchedUser = await fetchUser()
      setCurrentUser(fetchedUser)
    }

    const getUpcomingEvents = async () => {
      const fetchedUpcomingEvents = await fetchUpcomingEvents(user.uid)
      setUpcomingEvents(fetchedUpcomingEvents)
    }

    const getPastEvents = async () => {
      const fetchedPastEvents = await fetchPastEvents(user.uid)
      setPastEvents(fetchedPastEvents)
    }

    getUser()
    getUpcomingEvents()
    getPastEvents()
  }, [])

  const fetchUser = async () => {
    const res = await fetch(`${baseUrl}/users/${user.uid}`)
    const data = await res.json()
    return data
  }

  const handleAcceptInvite = (friend) => {
    updateFriendInvite(friend)

  }

  const handleDeclineInvite = (friend) => {
    declineFriendInvite(friend)
    const index = friendInvites.indexOf(friend)
    if(index > -1) {
      friendInvites.splice(index, 1)
    }
  }

  const updateFriendInvite = (friend) => {
    const updatedFriend = {...friend, status:'ACCEPTED'}
    fetch(`${baseUrl}/friends/update`, {
      method: 'POST',
      headers: {
        'Content-Type' : 'application/json'
      },
      body: JSON.stringify(updatedFriend)
    })
  }

  const declineFriendInvite = (friend) => {
    fetch(`${baseUrl}/friends/delete/${friend.id}`,{
      method: 'DELETE',
    })
  }

  const clickEvent = (event) => {
    navigate('/event', {state: event})
  }

  return (
    <div>
      <Sidebar/>
      <FriendsModal friends={friends} friendInvites={friendInvites} accept={handleAcceptInvite} decline={handleDeclineInvite} open={isOpen} close={()=>{setIsOpen(false)}}/>
      <div className='homepage-content-container'>
        <div className='profile-container'>
          <img className='profile-picture' src={profilePicture}></img>
          <h1>{currentUser.username}</h1>
          <p>{currentUser.bio}</p>
          <p onClick={()=>{setIsOpen(true)}}>Friends</p>
        </div>
        <div>
          <h1>Upcoming Events</h1>
          <Events events={upcomingEvents} clickEvent={clickEvent}/>
        </div>
        <div className='past-events-container'>
          <h1>Past Events</h1>
          <Events events={pastEvents} clickEvent={clickEvent}/>
        </div>
      </div>
    </div>
  )
}

export default Profile
