import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useState} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment'
import { baseUrl } from '../api';

const EditEvent = () => {
  const navigate = useNavigate()
  const format = 'MMM DD, YYYY, H:mm:00 A'
  const { state } = useLocation()
  const event = state
  const {title, location, startDateTime, endDateTime, privacy, description} = event
  const startDate = moment.utc(startDateTime).local()
  const endDate = moment.utc(endDateTime).local()
  const [editTitle, setEditTitle] = useState(title)
  const [editLocation, setEditLocation] = useState(location)
  const [editStartDate, setEditStartDate] = useState(startDate)
  const [editEndDate, setEditEndDate] = useState(endDate)
  const [minDate, setMinDate] = useState(startDate)
  const [editPrivacy, setEditPrivacy] = useState(privacy)
  const [editDescription, setEditDescription] = useState(description)

  const handleSave = (e) => {
    e.preventDefault()
    const finalStartDateTime = editStartDate.utc().format(format)
    const finalEndDateTime = editEndDate.utc().format(format)
    const updatedEvent = {
        ...event, 
        title:editTitle, 
        location:editLocation, 
        startDateTime:finalStartDateTime,
        endDateTime:finalEndDateTime,
        privacy:editPrivacy,
        description:editDescription
    }
    try {
        updateEvent(updatedEvent)
        navigate(-1, {state: updatedEvent})
        //navigate('/event', {state: updatedEvent})
    } catch(e) {
        console.error(e)
    }
  }

  //update event in backend
  const updateEvent = async (event) => {
    const res = await fetch(`${baseUrl}/events/update/` + event.id, {
        method: 'POST',
        headers: {
            'Content-type': 'application/json'
        },
        body: JSON.stringify(event)
    })
  }

  const handleDelete = (e) => {
    e.preventDefault()
    try {
        deleteEvent(event.id)
        navigate(-2)
    } catch (e) {
        console.error(e)
    }
  }

  //delete event in backend
  const deleteEvent = async (id) => {
    const res = await fetch(`${baseUrl}/events/${id}`, {
        method: 'DELETE',
    })
  }

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
        <div className='form-container'>
            <form className='form'>
                <h1>Create Event</h1>
                <div className='form-group'>
                    <label>Title</label>
                    <input type='text' defaultValue={title} onChange={(e) => {
                        setEditTitle(e.target.value)
                    }}></input>
                </div>
                <div className='form-group'>
                    <label>Location</label>
                    <input type='text' defaultValue={location} onChange={(e) => {
                        setEditLocation(e.target.value)
                    }}></input>
                </div>
                <div className='form-group'>
                    <label>Start Date</label>
                    <DateTimePicker 
                        defaultValue={startDate}
                        disablePast 
                        onChange={(e) => {
                            setEditStartDate(e)
                            setMinDate(moment(e).add(1, 'minutes'))
                        }} 
                    />
                </div>
                <div className='form-group'>
                    <label>End Date</label>
                    <DateTimePicker 
                        defaultValue={endDate}
                        minDateTime={minDate}
                        onChange={(e) => {
                            setEditEndDate(e)
                        }}
                    />
                </div>
                <div className='form-group'>
                    <label>Privacy</label>
                        <select defaultValue={privacy} onChange={(e) => {
                            setEditPrivacy(e.target.value)
                        }}>
                            <option value='PUBLIC'>Public</option>
                            <option value='PRIVATE'>Private</option>
                            <option value='FRIENDS'>Friends</option>
                        </select>
                </div>
                <div className='form-group'>
                    <label>Description</label>
                    <input type='textarea' defaultValue={description} onChange={(e) => {
                        setEditDescription(e.target.value)
                    }}></input>
                </div>
                <div className='form-button'>
                    <button className='btn' onClick={handleSave}>Save</button>
                    <button id='delete' className='btn' onClick={handleDelete}>Delete</button>
                </div>
            </form>
        </div>
    </LocalizationProvider>
  )
}

export default EditEvent
