import React, { useEffect, useState} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { UserAuth } from '../contexts/AuthContext'

const Login = () => {
    const [isInvalid, setIsInvalid] = useState(false)
    const [loginEmail, setLoginEmail] = useState('')
    const [loginPassword, setLoginPassword] = useState('')
    const { login } = UserAuth()
    const navigate = useNavigate()

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsInvalid(false)
        try {
            await login(loginEmail, loginPassword)
            navigate('/discover')
        } catch (e) {
            setIsInvalid(true)
            console.log(e.message)
        }
    }
    
  return (
    <div className='form-container'>
        <form className='form'>
            <h1>Login</h1>
            <div className='form-group'>
                <label>Email: </label>
                <input type='text' name='email' onChange={(e) => {
                    setLoginEmail(e.target.value)
                }}></input>
            </div>
            <div className='form-group'>
                <label>Password: </label>
                <input type='password' onChange={(e) => {
                    setLoginPassword(e.target.value)
                }}></input>
            </div>
            {isInvalid
                ? <div className='form-group'>
                    <p className='invalid'>Invalid Email/Password</p>
                </div>
                : <></>
            }
            <div className="form-button">
                <button className='btn' type='submit' onClick={handleSubmit}>Login</button>
            </div>
            <Link to='./signup'>Create Account</Link>
        </form>
    </div>
  )
}

export default Login
