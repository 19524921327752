import Sidebar from './Sidebar'
import Events from './Events'
import { useState, useEffect } from 'react'
import CreateButton from './CreateButton'
import { useNavigate } from 'react-router-dom'
import { UserAuth } from '../contexts/AuthContext'
import { useFetch } from '../hooks/useFetch'
import { baseUrl } from '../api'

const Home = () => {
  const { user } = UserAuth()
  const MY_EVENTS_URL = `${baseUrl}/events/user/${user.uid}`
  const [friendsEvents, setFriendsEvents] = useState([])
  const [pendingEvents, setPendingEvents] = useState([])
  const {data:myEvents, isLoading:myEventsLoading, error:myEventsError} = useFetch(MY_EVENTS_URL)

  const navigate = useNavigate()

  //Clicked event
  const clickEvent = (event) => {
    navigate('/event', {state: event})
  }

  const toCreateEvent = () => {
    navigate('/create_event')
  }

  return (
    <div>
      <Sidebar/>
      <div className='homepage-content-container'>
        <div className='events-container'>
          <h1>My Events</h1>
          <div>
              <Events events={myEvents} clickEvent={clickEvent}/>
          </div>
        </div>
        <CreateButton onClick={toCreateEvent} />
      </div>
    </div>
  )
}

export default Home
