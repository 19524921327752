import Friend from "./Friend"

const Friends = ({friends}) => {
  if(friends.length === 0) return <h1>Add some friends!</h1>
  return (
    <>
      <div className='friends-rows'>
        {friends.map((friend) => (
            <Friend key={friend.id} friend={friend}/>
        ))}
      </div>
    </>
  )
}

export default Friends
