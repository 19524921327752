const Friend = ({friend}) => {
  return (
    <>
      <div className='friend-row'>
        <p>{friend.username}</p>
      </div>
    </>
  )
}

export default Friend
