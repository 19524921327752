import { createContext, useContext } from "react";
import { baseUrl } from "../api";

const EventContext = createContext()

export const EventContextProvider = ({children}) => {
    const fetchUpcomingEvents = async (id) => {
        const res = await fetch(`${baseUrl}/events/upcoming/${id}`)
        const data = await res.json()
        return data
    }

    const fetchPastEvents = async (id) => {
        const res = await fetch(`${baseUrl}/events/past/${id}`)
        const data = await res.json()
        return data
    }

    return (
        <EventContext.Provider value ={{
            fetchUpcomingEvents, 
            fetchPastEvents
        }}>
            {children}
        </EventContext.Provider>
    )
}

export const UseEventContext = () => {
    return useContext(EventContext)
}