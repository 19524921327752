import SearchBar from "../components/SearchBar"
import '../stylesheets/search.css'
import { useNavigate } from "react-router-dom"

const SearchModal = ({open, close}) => {
  const navigate = useNavigate()
  if(!open) return null


  const clickResult = (user) => {
    close()
    navigate('/others_profile', {state: user})
  }

  return (
    <>
        <div className='overlay' onClick={()=>{close()}}></div>
        <div className='search-modal'>
            <SearchBar placeholder={'Enter username...'} clickResult={clickResult}/>
        </div>
    </>
  )
}

export default SearchModal
