import { Tab, Box } from "@mui/material"
import { TabContext, TabList, TabPanel } from '@mui/lab'
import '../stylesheets/friends.css'
import { useState } from "react"
import Friends from "../components/Friends"
import FriendInvites from "../components/FriendInvites"

const FriendsModal = ({friends, friendInvites, accept, decline, open, close}) => {
  const [value, setValue] = useState('1')
  const handleChange = (e, newValue) => {
    setValue(newValue)
  }
  if(!open) return null

  const handleClick = (e) => {
    
  }

  return (
    <>
      <div className='overlay' onClick={()=>{close()}}></div>
      <div className='friends-modal'>
        <Box>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider'}}>
                    <TabList aria-label='Friends' variant='fullWidth' centered onChange={handleChange}>
                        <Tab label='Friends' value='1'/>
                        <Tab label='Invites' value='2'/>
                    </TabList>
                </Box>
                <TabPanel value='1'><Friends friends={friends}/></TabPanel>
                <TabPanel value='2'><FriendInvites invites={friendInvites} accept={accept} decline={decline}/></TabPanel>
            </TabContext>
        </Box>
      </div>
    </>
  )
}

export default FriendsModal
