import SearchIcon from '@mui/icons-material/Search';
import '../stylesheets/search.css'
import { useEffect, useState } from 'react';
import { baseUrl } from '../api';

const SearchBar = ({placeholder, clickResult}) => {
  const [searchTerm, setSearchTerm] = useState('-1')
  const [searchResults, setSearchResults] = useState([])

  useEffect(()=>{
    const getResults = async () => {
      const users = await fetchResults()
      setSearchResults(users)
    }

    getResults()
  }, [searchTerm])

  const fetchResults = async () => {
    const res = await fetch(`${baseUrl}/users/search/${searchTerm}`)
    const data = await res.json()
    return data
  }

  return (
    <>
    <div className='search-container'>
      <SearchIcon style={{color: 'blue'}}/>
      <input id='search-input' placeholder={placeholder} onChange={(e)=> {
            if(e.target.value === '') {
              setSearchTerm('-1')
            } else {
              setSearchTerm(e.target.value)
            }
          }
          }></input>
    </div>
    <div className='search-results-list'>
      {searchResults.length === 0
        ? <div>No Results</div>
        : searchResults.map((value, key) => {
          return <div key={key} className='search-result-row' onClick={()=>clickResult(value)}>{value.username}</div>
        })
      }
    </div>
    </>
    
  )
}

export default SearchBar
