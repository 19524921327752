import Event from './Event'

const Events = ({ events, clickEvent }) => {

  return (
    <>
      {events.map((event) => (
        <Event key={event.id} event={event} clickEvent={clickEvent} />
      ))}
    </>
  )
}

export default Events
