import moment from 'moment'

const Event = ( {event, clickEvent} ) => {
  const startDate = moment.utc(event.startDateTime, 'MMM DD, YYYY, H:mm:00 A').local()
  const month = startDate.format('MMM')
  const day = startDate.format('DD')
  const time = startDate.format('HH:mm')

  return (
    <div className='event' onClick={() => {
      clickEvent(event)
    }}>
        <h1>{event.title}</h1>
        <h1>{month}<br/>{day}</h1>
        <h1></h1>
        <p className='event-time'>{time}</p>
    </div>
  )
}

export default Event
