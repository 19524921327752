import React from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import EventInvites from '../components/EventInvites';

const EventInviteModal = ({invite, friends, open, close}) => {
  if(!open) return null
  return (
    <>
      <div className='overlay' onClick={()=>{close()}}></div>
      <div className='modal'>
        <IconButton className='close-icon' onClick={() => {close()}}>
          <CloseIcon></CloseIcon>
        </IconButton>
        <EventInvites friends={friends}/>
      </div>
    </>
  )
}

export default EventInviteModal
