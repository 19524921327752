import { IconButton } from "@mui/material"
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

const FriendInvite = ({invite, accept, decline, onClick}) => {
  return (
    <>
      <div className='friend-invite-row' onClick={onClick}>
        <p>{invite.senderUsername}</p>
        <div id='friend-invite' className='two-btn-group'>
            <IconButton onClick={()=>accept(invite)}style={{color: 'green'}}><CheckIcon/></IconButton>
            <IconButton onClick={()=>decline(invite)}style={{color: 'red'}}><CloseIcon/></IconButton>
        </div>
      </div>
    </>
  )
}

export default FriendInvite
