import { useState, useEffect} from 'react'

export const useFetch = (url) => {
    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true)
            try {
                const res = await fetch(url)
                if(!res.ok) throw new Error(res.statusText)
                const json = await res.json()
                setData(json)
                setIsLoading(false)
                setError(null)
            } catch(e) {
                setError(`${e} Could not fetch data`)
                setIsLoading(false)
            }
        }

        fetchData()
    }, [url])

    return { data, isLoading, error }
}