import { createContext, useContext } from 'react'
import { baseUrl } from '../api'

const ParticipantContext = createContext()

export const ParticipantContextProvider = ({children}) => {
    const noParticipant = {
        id: '',
        eventId: '',
        userId: '',
        status: 'NOT_GOING'
    
    }

    const fetchParticipant = async (eventId, userId) => {
        try {
            const res = await fetch(`${baseUrl}/participants/${eventId},${userId}`)
            const data = res.json()
            return data
        } catch(e) {
            return noParticipant
        }
    }

    const addParticipant = async (newParticipant) => {
        await fetch(`${baseUrl}/participants/add`, {
        method: 'POST',
        headers: {
            'Content-type': 'application/json'
        },
        body: JSON.stringify(newParticipant)
        })
    }

    const updateParticipant = async (participant) => {
        await fetch(`${baseUrl}/participants/update/${participant.id}`, {
            method: 'POST',
            headers: {
                'Content-Type' : 'application/json',
            },
            body: JSON.stringify(participant)
        })
    }

    const deleteParticipant = async (id) => {
        await fetch(`${baseUrl}/participants/${id}`, {
            method: 'DELETE',
        })
    }

    return (
        <ParticipantContext.Provider value={{
            fetchParticipant,
            addParticipant,
            updateParticipant,
            deleteParticipant
        }}>
            {children}
        </ParticipantContext.Provider>
    )
}

export const UseParticipantContext = () => {
    return useContext(ParticipantContext)
}