import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment'
import { UserAuth } from '../contexts/AuthContext';
import { baseUrl } from '../api';

const CreateEvent = () => {
  const navigate = useNavigate()
  const format = 'MMM DD, YYYY, H:mm:00 A'
  const defaultStartDate = moment().add(1, 'hours')
  const defaultEndDate = moment().add(2, 'hours')
  const defaultPrivacy = 'PUBLIC'

  const [title, setTitle] = useState('')
  const [location, setLocation] = useState('')
  const [startDate, setStartDate] = useState(defaultStartDate)
  const [endDate, setEndDate] = useState(defaultEndDate)
  const [minDate, setMinDate] = useState(defaultStartDate)
  const [privacy, setPrivacy] = useState(defaultPrivacy)
  const [description, setDescription] = useState('')
  const { user } = UserAuth()

  //handle submit
  const onCreate = async (e) =>  {
    e.preventDefault()
    const startDateTime = startDate.utc().format(format)
    const endDateTime = endDate.utc().format(format)
    const hostUserId = user.uid
    const event = {title, location, startDateTime, endDateTime, privacy, description, hostUserId}
    addEvent(event)
    navigate('/discover')
  }

  //add event
  const addEvent = async (event) => {
    const res = await fetch(`${baseUrl}/events/add`, {
        method: 'POST',
        headers: {
            'Content-type': 'application/json'
        },
        body: JSON.stringify(event)
    })
  }

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
        <div className='form-container'>
            <form className='form'>
                <h1>Create Event</h1>
                <div className='form-group'>
                    <label>Title</label>
                    <input type='text' onChange={(e) => {
                        setTitle(e.target.value)
                    }}></input>
                </div>
                <div className='form-group'>
                    <label>Location</label>
                    <input type='text' onChange={(e) => {
                        setLocation(e.target.value)
                    }}></input>
                </div>
                <div className='form-group'>
                    <label>Start Date</label>
                    <DateTimePicker 
                        defaultValue={startDate}
                        disablePast 
                        onChange={(e) => {
                            setStartDate(e)
                            setMinDate(moment(e).add(1, 'minutes'))
                        }} 
                    />
                </div>
                <div className='form-group'>
                    <label>End Date</label>
                    <DateTimePicker 
                        defaultValue={endDate}
                        minDateTime={minDate}
                        onChange={(e) => {
                            setEndDate(e)
                        }}
                    />
                </div>
                <div className='form-group'>
                    <label>Privacy</label>
                        <select defaultValue='PUBLIC' onChange={(e) => {
                            setPrivacy(e.target.value)
                        }}>
                            <option value='PUBLIC'>Public</option>
                            <option value='PRIVATE'>Private</option>
                            <option value='FRIENDS'>Friends</option>
                        </select>
                </div>
                <div className='form-group'>
                    <label>Description</label>
                    <input type='textarea' onChange={(e) => {
                        setDescription(e.target.value)
                    }}></input>
                </div>
                <div className='form-button'>
                    <button className='btn' onClick={onCreate}>Create</button>
                </div>
            </form>
        </div>
    </LocalizationProvider>
  )
}

export default CreateEvent
