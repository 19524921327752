import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { UserAuth } from '../contexts/AuthContext'

const SignUp = () => {
  const [isInvalid, setIsInvalid] = useState(false)
  const [userCredentials, setUserCredentials] = useState({
    email: '',
    password: '',
    username: ''
  })
  const [createEmail, setCreateEmail] = useState('')
  const [createPassword, setCreatePassword] = useState('')
  const [error, setError] = useState('')
  const navigate = useNavigate()
  const { createUser } = UserAuth()

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsInvalid(false)
    try {
        await createUser(userCredentials)
        navigate('/discover')
    } catch(e) {
        setIsInvalid(true)
        setError(e.message)
        console.log(e.message)
    }
  }

  const handleChange = (e) => {
    setUserCredentials((prev) => {
        return {
            ...prev,
            [e.target.name]: e.target.value,
        }
    })
  }

  return (
    <div className='form-container'>
        <form className='form'>
            <h1>Signup</h1>
            <div className='form-group'>
                <label>Email: </label>
                <input type='text' name='email' onChange={handleChange}></input>
            </div>
            <div className='form-group'>
                <label>Username: </label>
                <input type='text' name='username' onChange={handleChange}></input>
            </div>
            <div className='form-group'>
                <label>Password: </label>
                <input type='password' name='password' onChange={handleChange}></input>
            </div>
            <div className="form-button">
                <button className='btn' type='submit' onClick={handleSubmit}>Create Account</button>
            </div>
            {isInvalid
                ? <div className='form-group'>
                    <p className='invalid'>Email example: example@example.ca</p>
                    <p className='invalid'>Password must be at least 6 characters</p>
                </div>
                : <></>
            }
            <Link to='/'>Login</Link>
        </form>
    </div>
  )
}

export default SignUp
