import LanguageIcon from '@mui/icons-material/Language';
import HomeIcon from '@mui/icons-material/Home';
import Person2Icon from '@mui/icons-material/Person2';

export const SidebarData = [
    {
        title: 'Home',
        icon: <HomeIcon />,
        link: '/home'
    },
    {
        title: 'Discover',
        icon: <LanguageIcon />,
        link: '/discover'
    },
    {
        title: 'Profile',
        icon: <Person2Icon />,
        link: '/profile'
    },
]
