import React from 'react'

const EventInvite = ({friend}) => {
  return (
    <>
      <div className='invite-row'>
        <p>{friend.username}</p>
        <button id='invite' className='btn'>Invite</button>
      </div>
    </>
  )
}

export default EventInvite
