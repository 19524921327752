import Events from './Events'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import CreateButton from './CreateButton'
import Sidebar from './Sidebar'
import { baseUrl } from '../api'
import { useFetch } from "../hooks/useFetch"

const Discover = () => {
  const PUBLIC_EVENTS_URL = `${baseUrl}/events/public`
  const {data:publicEvents, isLoading:publicIsLoading, error:publicError} = useFetch(PUBLIC_EVENTS_URL)
  const navigate = useNavigate()

  //Clicked event
  const clickEvent = (event) => {
    navigate('/event', {state: event})
  }

  const toCreateEvent = () => {
    navigate('/create_event')
  }

  return (
    <div className='home'> 
      <Sidebar/>
      <div className='homepage-content-container'>
        <h1>Discover</h1>
        <div className='events-list'>
          <Events events={publicEvents} clickEvent={clickEvent}/>
        </div>
        <CreateButton onClick={toCreateEvent}/>
      </div>
    </div>
  )
}

export default Discover
