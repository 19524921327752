import CreateEvent from "./components/CreateEvent";
import Discover from "./components/Discover";
import EventInfo from "./components/EventInfo";
import Login from "./components/Login";
import SignUp from "./components/SignUp";
import Home from "./components/Home";
import Profile from "./components/Profile";
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import { AuthContextProvider } from './contexts/AuthContext'
import EditEvent from "./components/EditEvent";
import { ParticipantContextProvider } from "./contexts/ParticipantContext";
import OthersProfile from "./components/OthersProfile";
import { EventContextProvider } from "./contexts/EventContext";

function App() {
  return (
    <div className='App'>
      <Router>
        <AuthContextProvider>
          <ParticipantContextProvider>
            <EventContextProvider>
              <Routes>
                <Route path='/event' element={<EventInfo/>} />
                <Route path='/create_event' element={<CreateEvent/>} />
                <Route path='/' element={<Login/>}/>
                <Route path='/signup' element={<SignUp/>}/>
                <Route path='/discover' element={<Discover/>}/>
                <Route path='/home' element={<Home/>} />
                <Route path='/profile' element={<Profile/>} />
                <Route path='/edit_event' element={<EditEvent/>} />
                <Route path='/others_profile' element={<OthersProfile/>}/>
              </Routes>
            </EventContextProvider>
          </ParticipantContextProvider>
        </AuthContextProvider>
      </Router>
    </div>
  );
}

export default App;
