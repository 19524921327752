import { createContext, useContext, useEffect, useState } from 'react'
import { signInWithEmailAndPassword, 
         createUserWithEmailAndPassword, 
         signOut, 
         onAuthStateChanged 
} from 'firebase/auth'
import { auth } from '../config/Firebase'
import { baseUrl } from '../api'

const UserContext = createContext()

export const AuthContextProvider = ({children}) => {
    const [user, setUser] = useState({})
    const createUser = (userCredentials) => {
        createUserWithEmailAndPassword(auth, userCredentials.email, userCredentials.password).then((value) => {
            const newUser = {id:value.user.uid, email:userCredentials.email, username:userCredentials.username, bio:''}
            createUserInDatabase(newUser)
        })
    }

    const login = (email, password) => {
        return signInWithEmailAndPassword(auth, email, password)
    }

    const logout = () => {
        return signOut(auth)
    }

    const createUserInDatabase = (newUser) => {
        fetch(`${baseUrl}/users/add`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(newUser)
        })
    }

    //check to see if user logged in
    useEffect(() => {
        const unsuscribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser)
        })
        return () => {
            unsuscribe()
        }
    }, [])

    return (
        <UserContext.Provider value={{createUser, login, logout, user}}>
            {children}
        </UserContext.Provider>
    )
}

export const UserAuth = () => {
    return useContext(UserContext)
}