import React from 'react'
import EventInvite from './EventInvite'

const EventInvites = ({friends}) => {
  if(friends.length === 0) return <h1>No friends to invite</h1>
  return (
    <>
      <div className='invite-rows'>
        {friends.map((friend) => (
          <EventInvite key={friend.id} friend={friend}/>
        ))}
      </div>
    </>
  )
}

export default EventInvites
