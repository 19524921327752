// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'

const firebaseConfig = {
  apiKey: "AIzaSyBMVLusb2WpOaxBWcgHMlcG-L7xXN0ZKZg",
  authDomain: "event-project-e5be2.firebaseapp.com",
  projectId: "event-project-e5be2",
  storageBucket: "event-project-e5be2.appspot.com",
  messagingSenderId: "146158883329",
  appId: "1:146158883329:web:08485d565e7d2247f26600",
  measurementId: "G-55PEPWQYKF"
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
export const auth = getAuth(app)
