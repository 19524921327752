import { SidebarData } from "./SidebarData"
import { useNavigate } from "react-router-dom"
import LogoutIcon from '@mui/icons-material/Logout';
import { UserAuth } from "../contexts/AuthContext";
import SearchIcon from '@mui/icons-material/Search';
import { useState } from "react";
import SearchModal from '../modals/SearchModal'

const Sidebar = () => {
  const navigate = useNavigate()
  const {logout} = UserAuth()
  const [isOpen, setIsOpen] = useState(false)

  const handleLogout = async () => {
    try {
      await logout()
      navigate('/')
    } catch(e) {
      console.log(e.message)
    }
  }

  return (
    <>
      <SearchModal open={isOpen} close={()=> {setIsOpen(false)}}/>
      <div className='sidebar'>
        <ul className='sidebar-list'>
          {SidebarData.map((val, key) => {
              return (
                  <li className='sidebar-row' key={key} onClick={() => { navigate(val.link) }}> 
                    <div id='icon'>{val.icon}</div>
                    <div id='title'>{val.title}</div>
                  </li>
              )
          })}
          <div id='search'>
            <li className='sidebar-row' onClick={()=>setIsOpen(true)}>
              <div id='icon'><SearchIcon/></div>
              <div id='title'>Search</div>
            </li>
          </div>
          <div id='logout'>
              <li className='sidebar-row' onClick={handleLogout}>
                  <div id='icon'><LogoutIcon/></div>
                  <div id='title'>Logout</div>
              </li>
          </div>
        </ul>
      </div>
    </>
  )
}

export default Sidebar
